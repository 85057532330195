import React from 'react';
import { useLocation } from '@reach/router';

import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { GTM } from '@src/analytics/gtm';

const tabStyles = {
  tab2NonSelectStyle:
    'border-r-[4px] border-t-[4px] border-[#004246] bg-v5-green-700 rounded-tr-[16px] text-[#7A8B87]',
  tab1NonSelectStyle:
    'border-l-[4px] border-t-[4px] border-[#004246] bg-v5-green-700 text-[#7A8B87]',
  tab1SelectStyle: 'z-10 rounded-tr-[20px]  bg-v5-green-500 ',
  tab2SelectStyle: 'z-10 rounded-tl-[20px] bg-v5-green-500',
};

export default function DevCurriculumTabs({
  selectedCurriculumTabIndex,
  setSelectedCurriculumTabIndex,
  slug,
  type,
}) {
  const location = useLocation();
  const tabIndex = selectedCurriculumTabIndex;
  const handleClick = (idx) => {
    if (idx === 0) {
      window.history.replaceState({}, document.title, `${location.pathname}`);
    } else {
      window.history.replaceState(
        {},
        document.title,
        `${location.pathname}#senior`,
      );
    }
    setSelectedCurriculumTabIndex(idx);

    GTM.track(gtmEvents.CURRICULUM_TAB_SWITCHED, {
      slug: slug,
      type: type,
      selectedCurriculumType:
        idx === 0 ? 'Aspiring Developers' : 'Aspiring Senior Developers',
    });
  };
  return (
    <div className="relative mb-2 flex h-[80px] items-center rounded-t-[20px] md:h-[114px]">
      {/* sm:w-[calc(50%+5px)] is added to overlap one tab over other. */}
      <div
        className={`absolute flex h-full w-[51%] items-center justify-center rounded-tl-[20px] text-center text-xl sm:w-[calc(50%+7px)] md:cursor-pointer
md:text-2xl ${
          tabIndex === 0
            ? tabStyles.tab1SelectStyle
            : tabStyles.tab1NonSelectStyle
        }`}
        onClick={() => {
          handleClick(0);
        }}
      >
        <span className="w-[80%] font-manrope text-[16px] font-extrabold sm:w-full md:text-[24px]">
          Aspiring Developers
        </span>
        {tabIndex === 0 && (
          <div
            className={`absolute bottom-0 h-[6px] w-full bg-[#009378]`}
          ></div>
        )}
      </div>
      <div
        className={`absolute right-0 flex h-full w-[51%] items-center justify-center rounded-tr-[20px] text-center text-xl sm:w-[50%] md:cursor-pointer md:text-2xl  ${
          tabIndex === 1
            ? tabStyles.tab2SelectStyle
            : tabStyles.tab2NonSelectStyle
        }`}
        onClick={() => handleClick(1)}
      >
        <span className="w-[80%] font-manrope text-[16px] font-extrabold sm:w-full md:text-[24px]">
          Aspiring Senior Developers
        </span>
        {tabIndex === 1 && (
          <div
            className={`absolute bottom-0 h-[6px] w-full bg-[#009378]`}
          ></div>
        )}
      </div>
    </div>
  );
}
