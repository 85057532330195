import React, { useState } from 'react';
import { useStyles } from '../index';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpecializationOptions from './SpecializationOptions';
import RawHTML from '@components/Common/RawHTML/index';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Accordion } from '../index';
import Card from './Card';
import { useLocation } from '@reach/router';

const CustomAccordion = ({
  open,
  data,
  gradient,
  variant,
  isInnerAccordion,
  openAccordions,
  currentInnerAccordionIndex,
  currentAccordionIndex,
  currentTabName,
  setOpenAccordions,
}) => {
  const [expanded, setExpanded] = useState(open || false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [selectedSpecialization, setSelectedSpecialization] = useState(0);
  const [prevSelectedTab, setPrevSelectedTab] = useState(currentTabName);
  const location = useLocation();
  const classes = useStyles({ isExpanded: expanded });
  let cards = data?.cards || [];
  let subCards = data?.subCards || [];

  // Display a specified number of cards for aspiring and senior developers from the subCards array.
  const { specializationType } = data;
  let sliceFrom = 0;
  let sliceTill = subCards.length;

  if (specializationType && selectedSpecialization === 0) {
    sliceTill = specializationType[0]?.numberOfCards || sliceTill;
  } else if (specializationType && selectedSpecialization === 1) {
    sliceFrom = specializationType[0]?.numberOfCards || sliceFrom;
  }

  const image = getImage(data?.image);

  function extractTextFromHTML(htmlString) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || '';
  }

  const updateOpenAccordionStatus = (isExpanded) => {
    setOpenAccordions((prevState) => {
      const arr = [...prevState[currentTabName]];

      if (!isInnerAccordion) {
        arr[currentAccordionIndex] = isExpanded
          ? Array.from(
              { length: specializationType ? 2 : cards?.length },
              () => null,
            )
          : null;
        if (specializationType && isExpanded) {
          arr[currentAccordionIndex][selectedSpecialization] = true;
        }
      } else {
        const innerArr = [...(arr[currentAccordionIndex] || [])];
        innerArr[currentInnerAccordionIndex] = isExpanded ? true : null;
        arr[currentAccordionIndex] = innerArr;
      }

      return {
        ...prevState,
        [currentTabName]: arr,
      };
    });
  };

  const handleChange = (event, isExpanded) => {
    if (isExpanded && !dropdownOpened) {
      const extractedTitle = extractTextFromHTML(data?.title);

      // Track GTM event
      GTM.track(gtmEvents.CURRICULLUM_DROPDOWN_CLICK, {
        curriculumSection: extractedTitle,
        action: 'open',
        type: 'FELLOWSHIP',
      });

      // Set dropdownOpened to true to avoid tracking multiple times
      setDropdownOpened(true);
    }

    //store currently opened accordions in state to avoid losing open state
    updateOpenAccordionStatus(isExpanded);
    setExpanded(isExpanded);
  };

  if (variant === 'FAQ') {
    return (
      <FAQCustomAccordion
        data={data}
        expanded={expanded}
        handleChange={handleChange}
        classes={classes}
        image={image}
      />
    );
  }

  //   useEffect(() => {
  //     if (prevSelectedTab !== currentTabName) {
  //       updateOpenAccordionStatus(expanded);
  //       setPrevSelectedTab(currentTabName);
  //     }
  //   }, [selectedSpecialization, currentTabName]);

  //   useEffect(() => {
  //     if (openAccordions)
  //       openAccordions[currentTabName]
  //         ?.at(currentAccordionIndex)
  //         ?.forEach((ele, idx) => {
  //           if (ele) setSelectedSpecialization(idx);
  //         });
  //   }, [currentTabName]);

  return data ? (
    <Accordion
      disableGutters
      expanded={expanded}
      elevation={0}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
      className={`rounded-[20px] md:rounded-[30px] ${
        gradient
          ? 'border-gradient-radius30 border-gradient bg-black bg-opacity-30'
          : `${isInnerAccordion ? 'bg-v5-green-500' : 'bg-v5-green-700'}`
      }`}
      // {...(gradient && { style: { border: '1px solid #07634C' } })}
    >
      <AccordionSummary
        className={`w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6`}
        expandIcon={
          <div
            className={`flex h-[24px] w-[24px] items-center justify-center rounded-full ${
              isInnerAccordion ? 'bg-v5-green-700' : 'bg-v5-green-500'
            } text-v5-neutral-300`}
          >
            <FontAwesomeIcon
              icon={['fas', 'chevron-right']}
              className={`w-[10px] rotate-90 transition-all`}
            ></FontAwesomeIcon>
          </div>
        }
        classes={{ content: classes.content }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <div className="flex items-start gap-x-4">
            {image && (
              <div className="w-[40px]  min-w-[40px]">
                <GatsbyImage
                  placeholder="blurred"
                  loading="lazy"
                  image={image}
                  alt={'Curriculum'}
                  style={{ maxHeight: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
            )}
            <div>
              <h6
                className={`transition-all  sm:w-full ${
                  isInnerAccordion ? '' : 'md:text-[18px]'
                }`}
              >
                <RawHTML>{data?.title}</RawHTML>
              </h6>
              <p className="text-sm text-v5-neutral-300">{data?.duration}</p>
            </div>
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails
        className={`${
          gradient
            ? ''
            : `${isInnerAccordion ? 'bg-v5-green-500' : 'bg-v5-green-700'}`
        }  rounded-b-[30px] pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6`}
      >
        {isInnerAccordion && (
          <hr className="mb-5 border-b border-crio-green-400 opacity-20" />
        )}
        <div
          className={`${
            isInnerAccordion ? 'mt-2' : specializationType || 'md:pl-[54px]'
          }`}
        >
          {/* SPECIALIZATION TAB VIEW */}
          <SpecializationOptions
            specializationType={specializationType}
            selectedSpecialization={selectedSpecialization}
            setSelectedSpecialization={setSelectedSpecialization}
          />

          {/* DESCRIPTION */}
          {data.description && (
            <div
              className={`mb-4  text-[#B9CFCA] ${
                isInnerAccordion ? '' : 'text-[12px] md:text-[16px]'
              }`}
            >
              <RawHTML>{data?.description}</RawHTML>
            </div>
          )}
          {selectedSpecialization === 0 && (
            <div
              className={`mb-4  text-[#B9CFCA] ${
                isInnerAccordion ? '' : 'text-[12px] md:text-[16px]'
              }`}
            >
              <RawHTML>{data?.description1}</RawHTML>
            </div>
          )}
          {selectedSpecialization === 1 && (
            <div
              className={`mb-4  text-[#B9CFCA] ${
                isInnerAccordion ? '' : 'text-[12px] md:text-[16px]'
              }`}
            >
              <RawHTML>{data?.description2}</RawHTML>
            </div>
          )}

          {data?.skills?.length > 0 && (
            <div className="mt-3 mb-4 flex flex-wrap gap-x-3 gap-y-3">
              {data?.skills?.map((ele, idx) => (
                <div
                  key={idx}
                  className="rounded-[5px] border-[1px] border-v5-neutral-300 p-1 px-3 md:border-2"
                >
                  <h6 className="text-xs">{ele}</h6>
                </div>
              ))}
            </div>
          )}
        </div>
        {cards.length > 0 && (
          <>
            <div className="md:pl-[54px] md:pt-1">
              <h6 className="font-manrope text-white">{data.cardTitle}</h6>

              <div className="mt-10 flex flex-wrap items-stretch gap-5 md:my-4 ">
                {cards.map(
                  (ele, idx) =>
                    ele?.data !== null && (
                      <div
                        className={`${
                          idx === 0 && !data?.description
                            ? 'mt-[-30px]'
                            : 'mt-[-10px]'
                        } w-full`}
                        key={idx}
                        id={ele?.anchor}
                      >
                        <CustomAccordion
                          data={ele}
                          open={location.hash == '#' + ele?.anchor}
                          isInnerAccordion
                          currentInnerAccordionIndex={idx}
                          currentAccordionIndex={currentAccordionIndex}
                          currentTabName={currentTabName}
                          setOpenAccordions={setOpenAccordions}
                          open={
                            expanded &&
                            openAccordions[currentTabName][
                              currentAccordionIndex
                            ][idx]
                          }
                        ></CustomAccordion>
                      </div>
                    ),
                )}
              </div>
            </div>
          </>
        )}

        {subCards.length > 0 && (
          <>
            <div className={`md:pt-1`}>
              {/* <hr className="my-3 border-b border-crio-green-400 opacity-20" /> */}
              <h6 className="font-manrope text-white">{data?.cardTitle}</h6>

              <div className="mb-4 mt-1 grid justify-items-center gap-5 md:grid-cols-2">
                {subCards.slice(sliceFrom, sliceTill).map(
                  (ele, idx) =>
                    ele?.data !== null && (
                      <div
                        className={`${
                          (sliceTill - sliceFrom) % 2 !== 0 &&
                          idx === sliceTill - sliceFrom - 1
                            ? 'col-span-full'
                            : ele?.isCardLarge
                            ? 'col-span-full'
                            : ''
                        }
                        w-full`}
                        key={idx}
                        id={ele?.anchor}
                      >
                        <Card
                          data={ele}
                          specializationType={specializationType}
                        />
                      </div>
                    ),
                )}
              </div>
            </div>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

const FAQCustomAccordion = ({
  data,
  expanded,
  handleChange,
  classes,
  image,
}) => {
  return (
    <Accordion
      disableGutters
      expanded={expanded}
      elevation={0}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
      className={`rounded-[10px] bg-v5-green-700`}
      // {...(gradient && { style: { border: '1px solid #07634C' } })}
    >
      <AccordionSummary
        className={`w-full overflow-hidden p-2 px-4 text-v5-neutral-200 sm:px-6`}
        expandIcon={
          <div className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-v5-green-500 text-v5-neutral-300">
            <FontAwesomeIcon
              icon={['fas', 'chevron-right']}
              className={`w-[10px] rotate-90 transition-all`}
            ></FontAwesomeIcon>
          </div>
        }
        classes={{ content: classes.content }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <div className="flex items-center gap-x-4">
            <div className="w-[40px]  min-w-[40px]">
              <GatsbyImage
                placeholder="blurred"
                loading="lazy"
                image={image}
                alt={'Curriculum'}
                style={{ maxHeight: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
            <div>
              <h6 className={`text-base transition-all sm:w-full`}>
                <RawHTML>{data?.question}</RawHTML>
              </h6>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={`rounded-b-[30px] bg-v5-green-700 pt-0 pb-4 leading-relaxed text-v5-neutral-300 sm:px-6`}
      >
        <div className="md:pl-[20px]">
          <p>
            <RawHTML>{data?.answer}</RawHTML>
          </p>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
